import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import StaticHero from "@components/staticHero"
import Container from "@components/container"
import PrimaryCta from "@components/PrimaryCta"
import { heading1 } from "@styles/ui"
import BgImageSection from "@components/narcolepsy/BgImageSection"
import PatientVoice from "@components/PatientVoice"
import Quiz from "@components/quiz/narc/Quiz"
import { narcolepsyQuizData } from "@components/quiz/narc/QuizData"
import Section from "@components/section"
import Anchor from "@components/Anchor"

const TalkToYourDoctorPage = ({ location, data }) => {
  const RECAPTCHA_3_KEY = '6LdL8csqAAAAAC7PyiXqW3d5cCCshA4nlwgBHXmA'

  return (
    <Layout location={location} exitModalColour="orange">
      <Seo
        title="Talking To Your Doctor About 2 Symptoms of Narcolepsy | XYWAV®"
        description="Learn how to have a conversation with your doctor about EDS and cataplexy. Complete the doctor conversation starter checklist to help with your discussion. "
        location={location}
      />
      {/* Static hero */}
      <StaticHero eyebrow="TALKING TO YOUR DOCTOR" gradient="purple-red">
        <>
          Talk to your doctor about the <nobr>high-sodium</nobr>
          <br tw="hidden md:block" /> elephant in the room
        </>
      </StaticHero>

      <BgImageSection
        imageData={data}
        isWhiteBg
        widerImage
        mobileAspectRatio={"90%"}
        altText="A woman discussing her sodium intake and her narcolepsy symptoms"
        imageStyles={tw`2xl:(max-h-[335px])`}
        gradientStyles={tw`rotate-0 right-[unset] -left-[8rem]`}
        containerStyles={tw`xl:(col-start-7)`}
      >
        <>
          <div tw="before:(gradientRed block relative w-[124px] mb-[15px] h-1.5 rounded-md) xl:before:(mb-6 mt-0)"></div>
          <h2
            css={[
              heading1,
              tw`text-[22px] leading-[28px] text-merlin mb-[15px] pb-0 lg:(text-[25px] leading-[30px] mb-[25px] max-w-[38rem])`,
            ]}
          >
            Having an informed discussion—including talking about sodium
            intake—is important for people with&nbsp;narcolepsy
          </h2>
          <p tw="text-base mb-4">
            If you are considering low-sodium* XYWAV,{" "}
            <Anchor
              link="/narcolepsy/talk-to-your-doctor/#quiz"
              addedStyles={tw`!inline`}
            >
              here&apos;s how you can prepare for your next conversation
            </Anchor>
            .
          </p>
          <p tw="ml-1 mt-5 text-[0.75rem] mb-2 md:(mt-[25px] mb-0)">
            <span tw="relative -ml-1">*</span>XYWAV contains 131 mg of sodium at
            the maximum recommended nightly dose.
          </p>
        </>
      </BgImageSection>

      <Section bgColour={tw`lilac-gradient-vertical`}>
        <Container>
          <div tw="before:(gradientRed block relative w-[100px] mb-[15px] h-1.5 rounded-md mx-auto) xl:before:(mb-6 w-[135px])"></div>
          <h2
            css={[
              heading1,
              tw`text-[22px] leading-[28px] text-merlin text-center mb-[15px] pb-0 lg:(text-[25px] leading-[30px] mb-[25px])`,
            ]}
          >
            How are your symptoms of cataplexy and EDS in narcolepsy really
            affecting&nbsp;you?
          </h2>
          <p tw="text-base lg:text-center">
            These 3 questions may help you and your doctor have an informed
            discussion about your treatment plan and decide if XYWAV could be
            right for you.
            <br tw="hidden xl:block" /> Get started below and be sure to bring
            your results with you to your next doctor&apos;s visit.
          </p>
        </Container>
      </Section>

      <section id="take-quiz" tw="pb-[30px]">
        <Container addedStyles={tw`2xl:max-w-[1116px]`}>
          <div tw="md:scroll-mt-20" id="quiz">
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_3_KEY}>
              <Quiz data={narcolepsyQuizData} />
            </GoogleReCaptchaProvider>
          </div>
        </Container>
      </section>

      <PatientVoice
        quote={
          <>
            I was nervous about starting a new treatment; how it would fit in my
            schedule, what possible side effects I could have, and whether
            I&apos;d be able to take it since I was still taking a stimulant.
            But I&apos;m so happy I talked through my concerns with my doctor
            and decided to take the leap.”
          </>
        }
        patient={"Grace"}
        diagnosis={"Diagnosed with narcolepsy type 1."}
        imageData={data}
        id="grace-doctor-quote"
        footnote="Compensated for her time. Results may vary."
        lilacBg
        cta={{
          url: "/patient-stories/#grace",
          text: "WATCH GRACE'S STORY",
          width: tw`lg:max-w-fit`,
        }}
      />

      <Section addedStyles={tw`pt-7`}>
        <Container addedStyles={[tw`text-center`]}>
          <div tw="before:(gradientRed block relative w-[100px] mb-[15px] h-1.5 rounded-md mx-auto) xl:before:(mb-6 w-[135px])"></div>
          <h2
            css={[
              heading1,
              tw`text-[22px] leading-[28px] text-merlin mb-[15px] pb-0 lg:(text-[25px] leading-[30px] mb-[25px])`,
            ]}
          >
            Get the support for XYWAV when you need it
          </h2>

          <p tw="text-base lg:text-center mb-[15px] lg:(mb-[25px] text-xl)">
            If you&apos;re concerned about cost, insurance coverage, or how to
            get and take XYWAV, JazzCares is here to help.
          </p>

          <div tw="mx-auto">
            <PrimaryCta
              url={"/narcolepsy/jazzcares-for-xywav/"}
              noInterstitial
              mdSmall
              width={tw`lg:max-w-fit`}
            >
              SEE HOW JAZZCARES CAN HELP
            </PrimaryCta>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default TalkToYourDoctorPage

export const query = graphql`
  query {
    bgImageSection: file(
      relativePath: {
        eq: "talk-to-your-doctor/woman-with-doctor-sodium-elephant.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 960, placeholder: NONE)
      }
    }
    bgImageSectionMobile: file(
      relativePath: {
        eq: "talk-to-your-doctor/woman-with-doctor-sodium-elephant-mobile.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    colImage: file(
      relativePath: { eq: "talk-to-your-doctor/have-questions.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 470, placeholder: NONE)
      }
    }
    patientVoice: file(
      relativePath: { eq: "talk-to-your-doctor/grace-headshot.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 425, placeholder: NONE)
      }
    }
    patientVoiceMobile: file(
      relativePath: { eq: "talk-to-your-doctor/grace-headshot-mobile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
  }
`
